$rose: rgba(251,246,243,1);
$rouge: #df5a53ff;

@font-face {
 font-family: "Nunito-Sans-Regular";
 src: url("../assets/fonts/Nunito-Sans-Regular.woff") format("woff");
}

@font-face {
 font-family: "NunitoSans-Bold";
 src: url("../assets/fonts/NunitoSans-Bold.woff") format("woff");
}

@font-face {
 font-family: "Chalkduster";
 src: url("../assets/fonts/Chalkduster.woff") format("woff");
}

* {
  margin:0;
  padding:0;
  font-family:'Nunito-Sans-Regular', sans-serif;
}

html {
  height:100vh;
}

body {
  background-color:$rose;
  height:100vh;
}

.font__Chalkduster{
  font-family: "Chalkduster";
  font-weight:200;
}

.grid-container {
  display:grid;
  color:$rouge;
  grid-template-columns:26vw 74vw;
  grid-template-rows:66vh 34vh;
  grid-template-areas:
    "logo__container main"
    "bottom-left informations"
}

.main {
  border-bottom:#df5a53ff solid 2px;
  border-left:#df5a53ff solid 2px;
}

.logo__left {
  display: none;
}

.presentation__container {
  display:flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-left:0vw;
  height:100%;
  margin-bottom:1vh;
}

.presentation__container h1 {
  font-size:1.2rem;
}

.clients {
  position: relative;
  top:-5px;
  margin-bottom:18vh;
}

.clients li {
  display: inline;
  margin-right: 1px;
  font-size:0.8rem;
  text-decoration:none;
}

.contact {
  position: relative;
  top:4px;
  font-size: 0.95rem;
  font-weight:bold;
}

.bottom-left{
  position: relative;
  top:-1px;
  left:1px;
}

.informations__texte{
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  margin-top:6vh;
  text-align:center;

}

.informations__texte h2{
  text-align:center;
  font-size:0.8rem;
  font-weight:bold;
  margin-bottom:1vh;
}

.telephone {
  font-size:0.79rem;
  color:#df5a53ff;
}

a[href^="tel"] {
  color: inherit; 
  text-decoration: none;
}

.mail, .adresse {
  font-size:0.8rem;
}

.mail {
    unicode-bidi: bidi-override; direction: rtl;
}

.juridique {
  display:none;
  margin-top:7vh;
  padding-right:1vw;
  padding-bottom:1vw;
  font-size:0.6rem;
}

.logo__small {
  display:block;
  width:50%;
  margin-left:6vw;
  height:auto;
  margin-bottom: 4vh;
}

/* Pour les tablettes */

@media (orientation:landscape) and (max-width : 700px){
  .logo__small {
    display:none;
  }

  .logo__container {
    margin-top:7vh;
    max-width:25vw;
    margin-left:auto;
    margin-right:auto;
    transform: translateX(15%)
  }

  .informations__texte{
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
  }

  .logo__left {
    display:block;
    width:70%;
  }

  .clients {
    margin-bottom:50px;
  }

  .juridique {
    display:none;
  }
}

/* Pour les petits écrans */
@media only screen and (min-width:700px) {
  .presentation__container {
    align-items: center;
    margin-left:1vw;
  }

  .presentation__container h1 {
    font-size:1.6rem;
  }

  .presentation__container li {
    font-size:1.3rem;
  }
  
  .contact {
    font-size:1.25rem;
  }

  .clients {
    margin-bottom:22vh;
  }

  .logo__left {
    display:none;
  }

  .logo__small {
    display:block;
    width:25%;
    margin-bottom:4vh;
  }

  .informations__texte {
    text-align:center;
    margin-top:2.5vh;
    margin-left:1vw;
    justify-content: center;
    align-items: center;
  }

  .informations__texte h2 {
    text-align:center;
    font-size:0.95rem;
  }

  .telephone {
    font-size:0.94rem;
  }

  .mail, .adresse {
    font-size:0.95rem;
  }
  .juridique{
    display:block;
    text-align:center;
    font-size:0.75rem;
  }
}


/* Pour les grands écrans */
@media only screen and (min-width:920px) {

  .presentation__container {
    display:flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    margin-left:1vw;
    height:100%;
  }

  .logo__container {
    justify-self: center;
    margin-top:4vh;
    transform: translateX(-25%);
  }

  .logo__left {
    display:block;
    width:130%;
  }

  .logo__small {
    display:none;
  }
  
  .contact {
    font-size:1.25rem;
  }

  .informations__texte {
    text-align: center;
    margin-top:5vh;
    align-items: flex-start;
  }

  .informations__texte h2 {
    text-align:left;
  }

  .juridique {
    display:block;
    margin-top:12vh;
    padding-right:2vw;
    padding-bottom:2vw;
    text-align:right;
    font-size:0.7rem;
  }

}